import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroTwo'
import NewsSection from '../components/homeNews'
import ColumnOverlay from '../components/columnOverlay'
import ColumnAlternating from '../components/columnAlternatingStyled'
import HomeDigital from '../components/homeDigital'

const Index = ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="intro-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description} 
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.foreimage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
        />

        <Hero
          class="home-110"
          foreimage={post.frontmatter.foreimage.childImageSharp.gatsbyImageData}
          forealt={post.frontmatter.forealttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctaslug="#scrollto"
          ctatext="Why UNICOM Capital?"
          ctaslug2="/contact/"
          ctatext2="Schedule a consultation"
          type="home"
        />
      </div>

      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="#FFFFFF" ></path>
          </svg>
        </div>
      </div>

      <main id="main">
        <MDBContainer className="pb-100">
          <ColumnOverlay cardtagtext={post.frontmatter.cardtagtext} cardtitle={post.fields.frontmattermd.cardtitle.html} cardtext={post.fields.frontmattermd.cardtext.html} body={post.html} />
        </MDBContainer>

        {post.frontmatter.section ? (
          <section className="bg-white-grey-gradient">
            <MDBAnimation reveal type="fadeIn" duration="1587ms">
              <h2
                className="font-alt font-w-700 text-very-dark-blue title-xs-large title-large letter-spacing-1 text-center mt-4 mt-xl-0 mb-7"
                dangerouslySetInnerHTML={{ __html: post.fields.frontmattermd.spotlighttitle.html, }}
              ></h2>
              <MDBContainer>
                {post.frontmatter.section.map((sections, index) => {
                  return (
                    <ColumnAlternating
                      key={index}
                      title={sections.title}
                      subtitle={sections.subtitle}
                      subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : '' }
                      description={sections.description}
                      image={sections.image.childImageSharp.gatsbyImageData}
                      alt={sections.alttext}
                      placement={sections.placement}
                      titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                      link={sections.btnlink}
                      colour={ sections.btncolour != 'none' ? sections.btncolour : null }
                    />
                  )
                })}
              </MDBContainer>
            </MDBAnimation>
          </section>
        ) : (
          ''
        )}
        <HomeDigital />
        <NewsSection />
      </main>
    </Layout>
  )
}
export default Index 


export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(frontmatter: { name: { eq: "index" } }) {
      id
      html
      fields {
        frontmattermd {
          cardtitle {
            html
          }
          cardtext {
            html
          }
          spotlighttitle {
            html
          }
        }
      }
      frontmatter {
        meta {
          title
          description 
          keywords
        }
        cardtitle
        title
        subtitle
        cardtagtext
        cardtext
        foreimage {
          childImageSharp {
            gatsbyImageData(width: 540, quality: 90) 
          }
        }
        forealttext
        section {
          title
          subtitle
          subtitlecolour
          description
          image {
            childImageSharp {
              gatsbyImageData(width: 960, quality: 90) 
            }
          }
          placement
          alttext
          btnlink
          btncolour
        }
      }
    }
  }
`
